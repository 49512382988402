/* eslint-disable import/no-import-module-exports */
import { setConfig } from '../../config.js';
import { eventEmitter } from '../../events.js';
import { dom } from '../../global.js';
import { logger } from '../../utilities/logger.js';

const cmpLogger = logger({ name: 'consent', textColor: '#FFF', bgColor: '#550000' });

let consentConfig = {};
let consentGiven = false;
let performanceConsentGiven = false;
let consentFired = false;

export function getConsentConfig() {
	return consentConfig;
}
export function setConsentConfig(consentConfigValue) {
	consentConfig = consentConfigValue;
	cmpLogger.logInfo(`Config set`, consentConfig);
	return consentConfig;
}
export function getConsentGiven() {
	return consentGiven;
}
export function setConsentGiven(consentValue) {
  cmpLogger.logInfo(`Set Consent Given called to set to ${consentValue}`);
	if (consentValue === true || consentValue === false) {
		consentGiven = consentValue;
	}
	return consentGiven;
}
export function getPerformanceConsentGiven() {
	return performanceConsentGiven;
}
export function setPerformanceConsentGiven(consentValue) {
	if (consentValue === true || consentValue === false) {
		performanceConsentGiven = consentValue;
	}
	return performanceConsentGiven;
}
export function getConsentFired() {
	return consentFired;
}
export function setConsentFired() {
	consentFired = true;
	return consentFired;
}
/**
 * Function used by tests to reset the consentFired flag
 */
export function resetConsentFired() {
	consentFired = false;
}
export function checkForConsentState() {
	const consent =
		(consentConfig.type === 'onetrust' &&
			typeof dom().window.OptanonActiveGroups === 'string' &&
			(dom().window.OptanonActiveGroups.indexOf(',C0004,') !== -1 || dom().window.OptanonActiveGroups.indexOf(',4,') !== -1)) ||
		(consentConfig.type === 'zdconsent' && getConsentGiven() === true);
	const perfConsent =
		(consentConfig.type === 'onetrust' &&
			typeof dom().window.OptanonActiveGroups === 'string' &&
			(dom().window.OptanonActiveGroups.indexOf(',C0002,') !== -1 || dom().window.OptanonActiveGroups.indexOf(',2,') !== -1)) ||
		(consentConfig.type === 'zdconsent' && getPerformanceConsentGiven() === true);
	eventEmitter.emit('consentChanged', consent);
	cmpLogger.logInfo(`Targeting Consent Given`, consent);
	setConsentFired();
	setConsentGiven(consent);

  cmpLogger.logInfo(`Performance Consent Given`, perfConsent);
  setPerformanceConsentGiven(perfConsent);

	setConfig('consent', consent);

	return consent;
}

module.exports = { getConsentGiven, setConsentGiven, getPerformanceConsentGiven, setPerformanceConsentGiven,getConsentFired, setConsentFired, getConsentConfig, setConsentConfig, resetConsentFired, checkForConsentState };
